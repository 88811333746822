<template>
  <div>
    <main-template/>
    <desc-template/>
  </div>
</template>
<script>
import MainTemplate from "../components/index/main.vue";
import DescTemplate from "../components/index/desc.vue";
export default {
  components: { MainTemplate, DescTemplate },
};
</script>
<style lang="less" scoped>
.el-header,
.el-footer,
.el-main {
  padding: 0 !important;
}
.el-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .el-main {
    flex: 1;
  }
  .el-footer {
    background-color: #2d444f;
  }
}
</style>