<template>
  <div>
    <div class="container">
      <limg-rdes></limg-rdes>
      <ldes-rimg></ldes-rimg>
    </div>
    <div class="cooperation_box">
      <div class="content">
        <div>
          <div class="title"><h3>可靠合作伙伴</h3></div>
          <div class="span">
            作为企业的基础物流服务商，致远于给企业提供安全可靠的供应链合作服务体系，为进出口商保价护航。
          </div>
        </div>
      </div>
    </div>
    <div class="tger">
      <div class="box">
        <div class="enctn">
          <span>COOPERATION AND WIN-WIN,</span>
          <span>COMMON DEVELOPMENT,</span>
          <span>CREATE BRILLIANT</span>
        </div>
        <div class="content">双赢合作，共谋发展、共创辉煌</div>
        <div class="title">让我们合作开始吧</div>

      </div>
    </div>
  </div>
</template>
<script>
import LimgRdes from "../plugin/imgdesc/limg-rdes.vue";
import LdesRimg from "../plugin/imgdesc/ldes-rimg.vue";

export default {
  // name: "#Des",
  data() {
    return {};
  },
  components: { LimgRdes, LdesRimg },
};
</script>
<style lang="less" scoped>
.cooperation_box {
  background-color: #2c2434;
  width: 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/images/contentImgs/banner_dhl.png);
  background-repeat: no-repeat;
  background-position: right;
  background-position-x: right;
  background-position-y: center;
  .content {
    margin: 0 5%;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: var(--fontsize-h1);
      text-align: center;
    }
    .span {
      font-size: 15px;
      line-height: 30px;
      margin-top: 20px;
      text-align: center;
      color: #ddd;

    }
  }
}
.tger {
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient(
    130deg,
    #3c598d 0%,
    #61672e 60%,
    #2b3658 85%
  );
  .box {
    margin: 0 5%;
    .title {
      font-size: 25px;
      margin-bottom: 20px;
      text-align: center;
    }
    .content {
      text-shadow: 1px 1px 5px #111;
      font-size: 20px;
      text-align: center;
    }
    .enctn {
      margin-top: 30px;
      line-height: 20px;
      font-size: 13px;
      position: relative;
      color: #ccc;
    }
    .enctn::before {
      content: "-";
      color: greenyellow;
      font-size: 40px;
      position: absolute;
      top: -20px;
      left: 10px;
      transform: scaleX(3);
    }
  }
}
</style>