<template>
  <div class="main">
    <el-row type="flex" justify="center">
      <el-col>
        <div class="txtBox">
          <span>专业，让一切变得如此简单</span><span>一对一的解决方案</span>
        </div>
      </el-col>
    </el-row>
    <div class="serverList">
      <div class="serverList_title">
        <span>优势服务</span><span>Server List</span>
      </div>
      <div class="serverList_detail">
        <ul class="ul">
          <li>
            <a href="/agent/expressdoc">
              <div class="icon">
                <img src="~assets/images/customsbill.svg" />
              </div>
            </a>
            <div class="lable">单证服务</div>
            <div class="content">
              专业提供代理进出口服务，为企业解决进出口业务，降低进出口企业报关费用。
            </div>
          </li>
          <li>
            <a href="/agent/import">
              <div class="icon">
                <img src="~assets/images/customs.svg" />
              </div>
            </a>
            <div class="lable">进出口报关</div>
            <div class="content">
              提供进出口报关，服务于深圳各大海港口岸及过港陆运口岸,同时辐射珠三角地区各大港口：黄埔港、南沙港等港口的进出口报关业务。
            </div>
          </li>
          <li>
            <a href="supply">
              <div class="icon">
                <img src="~assets/images/supply.svg" />
              </div>
            </a>
            <div class="lable">货物供应链</div>
            <div class="content">
              提升进出口企业资金周转效率、解决信用融资难等问题，让企业专注于生产贸易。
            </div>
          </li>
          <li>
            <a href="/logistics">
              <div class="icon">
                <img src="~assets/images/express.svg" />
              </div>
            </a>
            <div class="lable">物流快递</div>
            <div class="content">
              本公司与各大国际航空快递公司签约，服务全球各地，
              主要优势于欧洲、美洲及东南亚各国，为跨境电商专家提供优质服务。
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>
.main {
  font-family: "圆体-简";
}
.el-row {
  height: 40rem;
  background-color: #f5f5f7;
  .txtBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-image: url("~assets/images/earth.png");
    background-repeat: no-repeat;
    background-size: 45rem;
    background-attachment: local;
    background-position: 130% 10rem;
    span {
      margin-top: -10rem;
      color: #333;
      font-size: 1.8rem;
      font-weight: normal;
      text-align: center;
    }
    span:last-child {
      margin-top: 0rem;
      color: #999;
      font-weight: lighter;
      font-size: 1.3rem;
      line-height: 2.8rem;
      text-align: center;
    }
  }
}
.serverList {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .serverList_title {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    color: #333;
    span {
      text-align: center;
    }
    span:first-child {
      margin-top: 3rem;
    }
    span:last-child {
      color: #999;
      font-size: 1.1rem;
    }
  }
  .serverList_detail {
    box-sizing: border-box;
    .ul {
      list-style: none;
      // columns: 4;
      // column-gap: 2rem;
      margin: 1.5rem 5%;
      padding: 0;
      display: inline-flex;

      li {
        // font-family: "黑体";
        break-inside: avoid-column;
        border: 2px solid #999;
        box-shadow: 1px 3px 5px #333;
        border-radius: 20px;
        width: 100%;
        box-sizing: border-box;
        margin: 0 20px 40px 0;
        padding: 20px;
        min-width: 155px;
        height: 365px;
        transform: scale(1);
        transition: transform 0.5s;
        -moz-transition: transform 0.5s; /* Firefox 4 */
        -webkit-transition: transform 0.5s; /* Safari and Chrome */
        -o-transition: transform 0.5s; /* Opera */
        background-image: url("~assets/images/cardimg/card-bgimg-200x200.png");
        .icon {
          height: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .lable {
          font-size: 1.3rem;
          line-height: 4rem;
          position: relative;
          margin-bottom: 10px;
        }
        .lable::before {
          content: "";
          border: 1px solid #999;
          position: absolute;
          width: 100%;
          top: 63px;
          left: -6px;
          transform: scaleY(0.5);
          transition: width 0.4s;
        }
        .lable::after {
          content: "_";
          color: red;
          position: absolute;
          left: -6px;
          top: 23px;
        }
        .content {
          font-size: 0.9rem;
          color: #666;
          font-family: "宋体";
        }
      }
      li:hover {
        transform: scale(1.03);
        background: rgb(248, 248, 248);
      }
      li:hover .lable::before {
        border-color: #2d444f;
        animation: lineAnm 0.5s ease;
      }
      @keyframes lineAnm {
        0% {
          width: 0;
        }
        100% {
          width: 100%;
          border-color: #2d444f;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .ul {
        columns: 1;
        display: block;
      }
    }
  }
}
</style>